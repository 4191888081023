<template>
    <div class="box">
        <div class="top">
            <ul class='left'>
                <li>
                    <span>Administrators</span>
                    <div class="num">{{ total }}</div>
                </li>
            </ul>

            <div class="right">
                <div class="search_box">
                    <input v-model="search" placeholder="Search by keywords" @keyup.enter="goSearch">
                    <div class="icon icon_search" @click="goSearch"></div>
                </div>
                <button @click="openPop(1)">Add new</button>
            </div>
        </div>
        <div class="con-table">
            <table>
                <thead>
                    <tr>
                        <th style="width: 5%;">Info</th>
                        <th style="width: 15%;">Nick name</th>
                        <th style="width: 16%;">Email</th>
                        <th style="width: 15%;">Location</th>
                        <th style="width: 15%;">Phone</th>
                        <th style="width: 12%;" class="icon_box">
                            Registration Date
                            <div class="icon icon_down" :style="{ 'transform': sort_data == 2 ? 'rotateX(180deg)' : '' }"
                                @click="sort_data = sort_data == 1 ? 2 : 1"></div>
                        </th>
                        <th style="width: 12%;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index" :class="index % 2 === 0 ? 'odd' : 'even'">
                        <td class="icon_box" @click="openPop(3, item)">
                            <div class="icon icon_info"></div>
                        </td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.country_name || 'not upload' }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ getDate(item.created_at) }}</td>
                        <td class="icon_box">
                            <div class="icon icon_del" @click="openPop_del(item)"></div>
                            <div class="icon icon_edit" @click="openPop(2, item)"></div>
                            <!-- <div class="icon icon_copy"></div> -->
                            <div v-if="item.id!=user_id" class="icon icon_chat" style="opacity: 0.6;margin-left:0" @click="goChat(item)" ></div>
                            <div class="icon icon_see" @click="openPop(3, item)"></div>
                        </td>

                    </tr>
                </tbody>
            </table>

        </div>
        <div class="pop-bg" v-if="bg" @click="close()">

            <div class="pop_box" v-if="pop_del" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        Delete administrators
                    </div>
                    <div class="text-gray left">
                        Are you sure you want to delete the administrators?
                    </div>
                    <div class="inp-box">
                        <textarea v-model="comment"></textarea>
                    </div>
                    <div class="button-box">
                        <button class="cancel" @click="close">No</button>
                        <button class="submit" @click="del">Yes</button>
                    </div>
                </div>

            </div>
            <div class="pop_box" v-if="pop_create&&popType!=3" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                        {{ popType == 1 ? 'New administrators' : 'Edit administrators' }}
                    </div>
                    <div class="img-box">
                        <img v-if="!photoIsChange" src="../../../assets/img/addImg.png" alt="" />
                        <div class="photo-box" v-loading="avatar == ''" v-else>
                            <img :src="avatar.full_path" alt="" />

                        </div>
                        <div class="img-btn">
                            <!-- <el-upload class="avatar-uploader" :headers="uploadHeader" :action="uploadUrl" method="post"
							:data="uploadData" list-type="picture" name='file' :show-file-list="false"
							:on-success="photoSuccess" :on-change="photoChange"> -->
                            <div class="left-btn">
                                Change photo
                                <input type="file" ref="fileInput" id="uploads"
                                    accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg">
                            </div>
                            <!-- 	</el-upload> -->

                            <div class="right-btn" @click="delPhoto">Delete photo</div>
                        </div>
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="username">Full Name</div>
                        <input v-model="username" placeholder="Full Name"
                            :style="{ 'margin-top': username ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="email">Email</div>
                        <input v-model="email" placeholder="Email" :style="{ 'margin-top': email ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="phone">Phone Number</div>
                        <input v-model="phone" placeholder="Phone Number"
                            :style="{ 'margin-top': phone ? '5px' : '20px' }" />
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" v-if="location">Location</div>
                        <el-select v-model="location" placeholder="Location" size="large" class="myselect"
                            @change="onChangeLocation" :style="{ 'margin-top': location ? '5px' : '20px' }">
                            <el-option v-for="item in locationList" :key="item.country_code" :label="item.country_name"
                                :value="item.country_code" />
                        </el-select>
                    </div>
                    <!-- <div class="inp-box">
						<div class="text-gray left" v-if="location">Role</div>
						<el-select v-model="role" placeholder="Role" size="large" class="myselect"
						    @change="onChangeRole"  :style="{'margin-top':location?'5px':'20px'}">
						    <el-option v-for="item in RoleList" :key="item.name" :label="item.name"
						        :value="item.name" />
						</el-select>
					</div> -->
                    <div class="button-box">
                        <button class="cancel" @click="close">Cancel</button>
                        <button class="submit" @click="save">Save</button>
                    </div>
                </div>

            </div>
            <div class="pop_box" v-if="pop_create&&popType==3" @click.stop>
                <div class="icon_close" @click="close"></div>
                <div class="pop">
                    <div class="title">
                      Administrators
                    </div>
                    <div class="img-box">
                        <div class="photo-box" >
                            <img :src="avatar.full_path" alt="" />
                        </div>
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" >Full Name</div>
                      <div class="input">{{ username }}</div>
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left">Email</div>
                        <div class="input">{{ email }}</div>
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left" >Phone Number</div>
                        <div class="input">{{ phone }}</div>
                    </div>
                    <div class="inp-box">
                        <div class="text-gray left">Location</div>
                        <div class="input">{{ location }}</div>
                    </div>
                </div>

            </div>
        </div>
        <pagination :total="total" :pageSize="pageSize" :nowPage="nowPage" @goPage="goPage" @nextPage='nextPage'
            @backPage='backPage'></pagination>
        <cropper :option="option" v-if="option.img" @savePhoto="savePhoto"></cropper>

    </div>
</template>

<script>
import {
    ElMessage
} from 'element-plus'
import Api from '../../../utils/api.js';
import pagination from '../../common/pagination.vue';
import cropper from '../../common/cropper.vue'
export default {
    components: {
        pagination,
        cropper
    },
    data() {
        return {
            user_id:'',
            search: '',
            pageSize: 8,
            nowPage: 1,
            total: 0,
            list: [],
            bg: false,
            pop_del: false,
            pop_create: false,
            popType: 1,
            comment: '',
            sort_data: 1,
            username: '',
            email: '',
            id: "",
            locationList: [],
            location: '',
            phone: '',
            role: '',
            roleList: [],
            avatar: '',
            photoIsChange: false,
            option: {
                img: '',
                size: 1,
                outputType: 'png',
            },
        }

    },
    watch: {
        sort_data(e) {
            this.getList();
        }
    },
    created() {

    },
    mounted() {
        this.user_id = localStorage.getItem('user_id');
        let table = document.getElementsByClassName('con-table')[0];
        this.pageSize = parseInt((table.clientHeight - 50) / 50);
        this.getList();
        this.getCountry();
        this.getRoleList();
    },
    methods: {
        goChat(item) {
			Api.chatHasGroup({
				user_id: item.id,
				user_type: 1
			}).then(res => {
				if (res.code == 200) {
                    item.group_id = res.data.group_id;
                    item.group_im_id = res.data.group_im_id;
					this.$router.push({
						name: 'support_chat',
						query: {
							other: JSON.stringify(item),
							folder:'admin'
						}
					})
				} else {
					ElMessage.error(res.message)
				}
			})
		},
        upload(e) {
            const formData = new FormData();
            formData.append("file", e);
            Api.uploadImages(formData).then(res => {
                if (res.code == 200) {
                    this.avatar = res.data[0];
                    this.photoIsChange = true;
                } else {
                    ElMessage.error(res.message)
                }
            }).catch(err => {
                ElMessage.error(err.message);
            })
        },
        savePhoto(e) {
            this.option = {
                img: '',
                size: 1,
                outputType: 'png',
            }
            this.$refs.fileInput.value = null;
            if (e) {
                this.upload(e);
            }
        },
        async uploadImg(e) {
            if (e.target.files.length === 0) {
                return
            }
            if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/bmp' && e.target
                .files[0].type !== 'image/png') {
                this.$message.error('Image format error!');
                return false;
            }

            let that = this;
            const file = e.target.files[0];
            that.option.outputType = file.type.split('/')[1];
            var reader = new FileReader(),
                img = new Image()
            reader.readAsDataURL(file)
            reader.onload = function (e) {
                img.src = e.target.result
            }
            img.onload = function () {
                that.option.img = img.src;
                // console.log(that.option);
            }
        },
        delPhoto() {
            this.avatar = '';
            this.photoIsChange = false;
        },
        getRoleList() {

        },
        getCountry() {
            Api.indexCountry().then(res => {
                if (res.code == 200) {
                    this.locationList = res.data;
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        onChangeLocation(e) {
            console.log(e)
        },
        openPop(e, item) {
            this.popType = e
            this.pop_create = true;
            this.bg = true;
            if (item) {
                this.id = item.id;
                this.username = item.username;
                this.email = item.email;
                this.location = item.country_name;
                this.phone = item.phone;
                if (item.avatar) {
                    this.avatar = {
                        full_path: item.avatar,
                        half_path: item.half_avatar
                    }
                    this.photoIsChange = true;
                }

            }
        },
        openPop_del(item) {
            this.bg = true;
            this.pop_del = true;
            this.id = item.id;
        },
        close() {
            this.pop_del = false;
            this.pop_create = false;
            this.bg = false;
            this.username = '';
            this.email = '';
            this.location = '';
            this.id = '';
            this.comment = '';
            this.phone = '';
            this.avatar = '';
            this.photoIsChange = false;
        },
        save() {
            if (this.username == '' || this.email == '' || this.phone == '' || this.avatar == ''||!this.location) {
                ElMessage('Please complete the information.');
                return;
            }
            if(this.username.length>14){
				ElMessage('The maximum length of the full name is 14 characters.');
				return;
			}
            if (this.popType == 1) {
                Api.administratorCreate({
                    username: this.username,
                    email: this.email,
                    phone: this.phone,
                    avatar: this.avatar.half_path,
                    country:this.location
                }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.close();
                    } else {
                        ElMessage.error(res.message)
                    }
                })
            } else {
                Api.administratorEdit({
                    admin_id: this.id,
                    username: this.username,
                    email: this.email,
                    phone: this.phone,
                    avatar: this.avatar.half_path,
                    country:this.location,
                }).then(res => {
                    if (res.code == 200) {
                        this.getList();
                        this.close();
                    } else {
                        ElMessage.error(res.message)
                    }
                })
            }

        },
        del() {
            if (this.comment == '') {
                ElMessage('Please complete the comment.');
                return;
            }
            Api.administratorDelete({
                admin_id: this.id,
                deleted_comment: this.comment
            }).then(res => {
                if (res.code == 200) {
                    this.getList();
                    this.close();
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        getDate(e) {
            const date = new Date(e ? e * 1000 : '');
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hour = date.getHours();
            let minute = date.getMinutes();
            month = month > 9 ? month : '0' + month;
            day = day > 9 ? day : '0' + day;
            hour = hour > 9 ? hour : '0' + hour;
            minute = minute > 9 ? minute : '0' + minute;
            return `${day}/${month}/${year}`
        },
        goSearch() {
            this.nowPage = 1;
            this.getList();
        },
        change_booster(e, item, index) {
            console.log(e, item);
            item.booster_name_choose = e;
            this.choose_booster_index = index;
        },
        getList() {
            Api.administratorList({
                limit: this.pageSize,
                search: this.search,
                sort: this.sort_data,
                page: this.nowPage
            }).then(res => {
                if (res.code == 200) {
                    this.list = res.data.data;
                    this.total = res.data.meta.pagination.total;
                } else {
                    ElMessage.error(res.message)
                }
            })
        },
        goPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        nextPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
        backPage(e) {
            this.nowPage = e
            this.getList();
            console.log('nowPage:', this.nowPage)
        },
    }
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 100%;
    position: relative;
}

.con-table {
    height: calc(100% - 106px);
}

.top .left li {
    width: auto;
}

.con-table table tr .icon_box.submit .icon {
    cursor: no-drop !important;
}

.pop .img-box img,
.photo-box {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 20px;
    margin: auto;
}

.pop .img-box .photo-box {
    border: 1px solid var(--img-border)
}

.pop .img-box .img-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

}

.pop .img-box .img-btn .left-btn,
.right-btn {
    border: 1px solid var(--submit);
    border-radius: 20px;
    color: var(--text-color);
    padding: 10px 0;
    width: 100px;
    cursor: pointer;
}

.pop .img-box .img-btn .left-btn {
    position: relative;
    margin-right: 10px;
}

.pop .img-box .img-btn .left-btn input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    cursor: pointer;
}

.pop .img-box .img-btn .right-btn {
    background-color: var(--submit);
}</style>